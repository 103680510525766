<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle.sidebar-section-1
      variant="outline-primary mr-50"
    >
      choose sections
    </b-button>

    <b-sidebar id="sidebar-section-1" shadow bg-variant="white" backdrop>
      <quiziesList @addsections="addsections" />
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import quiziesList from "@/views/sections pages/components/listCheckBox.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BImg,
    quiziesList,
  },
  //   props: ["selectedsections"],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    addsections(selectedsections) {
      console.log("this" + selectedsections);
      this.$emit("addsections", selectedsections);
    },
  },
  data() {
    return {
      selectedsections: [],
    };
  },
};
</script>
