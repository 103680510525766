<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <b-modal ref="my-modal" modal-class="modal-info" hide-footer title="update user">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="userInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">user Details</h5>
              <small class="text-muted"> Enter the user Details. </small>
            </b-col>
            <b-col md="12">
              <b-form-group class="auth-register-form mt-2" label="user profile image" label-for="CorseCoverImage">
                <validation-provider #default="{ errors }" name="CorseCoverImage">
                  <b-form-file id="CorseCoverImage" accept="image/*" v-model="user.profileImg" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-row class="justify-content-md-center">
                <b-form-radio v-model="user.active" name="some-radio9" :value="true" class="custom-control-success">
                  online
                </b-form-radio>
                <b-form-radio v-model="user.active" name="some-radio9" :value="false" class="custom-control-danger">
                  offline
                </b-form-radio>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-form-group class="auth-register-form w-100" label="name" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required|min:3">
                  <b-form-input id="name" v-model="user.name" :state="errors.length > 0 ? false : null"
                    placeholder="user name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Email" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="register-email" v-model="user.email" name="register-email"
                    :state="errors.length > 0 ? false : null" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="phone" label-for="register-email">
                <validation-provider #default="{ errors }" name="phone" vid="phone" rules="numeric">
                  <b-form-input id="register-phone" v-model="user.phone" name="register-phone"
                    :state="errors.length > 0 ? false : null" placeholder="01000000000" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="birthDate" label-for="birthDate">
                <validation-provider #default="{ errors }" name="birthDate" vid="birthDate" rules="required">
                  <b-form-datepicker id="register-birthDate" v-model="user.birthDate" name="register-birthDate"
                    :state="errors.length > 0 ? false : null"></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <validation-provider #default="{ errors }" name="Country" rules="required">
                <b-form-group label="Country" label-for="country">
                  <v-select v-model="user.nationality" :options="countries" :clearable="false" input-id="country" />
                  <small class="text-danger">{{ errors[0] }}</small>

                  <!-- <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback> -->
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- password -->
            <!-- <b-col md="6">
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:9"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-for="register-confirm-password"
                label="confirm password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password Confirmation"
                  vid="passwordConfirm"
                  rules="required|confirmed:password"
                  data-vv-as="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password-confirm"
                      v-model="user.passwordConfirm"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
        </validation-observer>
      </form>
      <b-row variant="info">
        <b-col md="3">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-secondary" block
            @click="hideModal">
            No
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-info" block @click="toggleModal">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span class="mr-50 text-info">update</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import useriesApi from "@/apiServices/userApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";
import vSelect from "vue-select";
import countries from "@/auth/countries";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  props: ["user"],
  mixins: [togglePasswordVisibility],

  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      countries,
      options: ["video", "pdf", "powerpoint"],
      prams: {
        id: "",
      },
    };
  },
  created() {
    this.user.password = "";
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    updateuser() {
      this.$refs.userInfoRules.validate().then((success) => {
        if (success) {
          useriesApi.updateuser(this.user).then((response) => {
            if (response.data) {
              this.$emit("updateuserFromlist", this.id);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "user updated",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          });
          this.$refs["my-modal"].toggle("#toggle-btn");
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      if (this.user.profileImg instanceof File) {
        const imageFormData = new FormData();
        imageFormData.append("image", this.user.profileImg);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.user.profileImg = response;
          this.updateuser();
        });
      } else {
        // delete this.user.email;
        delete this.user.profileImg;
        this.updateuser();
      }
    },
  },
};
</script>
