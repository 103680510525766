<template>
  <div>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <validation-observer ref="sectionInfoRules" tag="form">
        <b-row>
          <b-col md="12">
            <b-form-group label="title" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required|min:3"
              >
                <b-form-input
                  id="title"
                  v-model="section.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="section title "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="12">
            <b-form-group label="Content" label-for="Content">
              <validation-provider
                #default="{ errors }"
                name="Content"
                rules="required|min:20"
              >
                <b-form-textarea
                  id="Content"
                  placeholder="Content"
                  v-model="section.description"
                  :state="errors.length > 0 ? false : null"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col>
            <draggable
              v-model="section.items"
              handle=".drag-sections"
              tag="ul"
              class="p-0"
            >
              <b-card
                v-for="(item, index) in section.items"
                :id="item.id"
                :key="item.id"
                class="m-1"
              >
                <feather-icon icon="MoreVerticalIcon" class="drag-sections" />
                <b-row>
                  <b-col v-if="item.quiz" md="8">
                    <feather-icon v-if="item.quiz" icon="CheckSquareIcon" />
                    {{ item.quiz.title }}
                  </b-col>
                  <b-col v-else md="8">
                    <feather-icon icon="FileTextIcon" />
                    <b-avatar :src="item.lesson.imageCover" />
                    {{ item.lesson.title }}
                  </b-col>

                  <b-col md="2">
                    <b-row>
                      <b-col md="9">
                        <b-card-text class="mb-0"> preview </b-card-text>
                        <b-form-checkbox
                          class="custom-control-success"
                          name="check-button"
                          switch
                          v-model="item.is_preview"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="EyeIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="EyeOffIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="section.items.splice(index, 1)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </draggable>
            <br />
            <b-row>
              <b-col>
                <quizesSidebar :id="section._id" @addquizes="addquizes" />
              </b-col>
              <b-col>
                <lessonsSidebar :id="section._id" @addlessons="addlessons" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </form>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mt-50 w-100"
      variant="info"
      @click="updatesection"
    >
      <feather-icon icon="Edit2Icon" class="mr-50" />
      <span class="mr-50">update ====> {{ section.title }}</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import sectionsApi from "@/apiServices/sectionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import addQuestionsRepiter from "@/views/questions pages/components/repiter.vue";
import quizesSidebar from "@/views/quizes pages/components/quiziesSidebar.vue";
import lessonsSidebar from "@/views/lessons pages/components/lessonsSidebar.vue";
import draggable from "vuedraggable";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // addQuestionsRepiter,
    quizesSidebar,
    lessonsSidebar,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["section"],
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
  }),
  methods: {
    addlessons(lessons) {
      lessons.forEach((element) => {
        let temp = {
          lesson: element,
          type: "lesson",
          is_preview: false,
        };
        if (
          this.section.items.findIndex(
            (element) => element.lesson?._id === temp.lesson._id
          ) === -1
        ) {
          this.section.items.push(temp);
        }
      });
    },

    addquizes(quizes) {
      quizes.forEach((element) => {
        let temp = {
          quiz: element,
          type: "quiz",
          is_preview: false,
        };
        if (
          this.section.items.findIndex(
            (element) => element.quiz?._id === temp.quiz?._id
          ) === -1
        ) {
          this.section.items.push(temp);
        }
      });
    },
    updatesection() {
      let temp = [];
      this.section.items.forEach((element) => {
        if (element.quiz) {
          temp.push({
            quiz: element.quiz._id,
            type: "quiz",
            is_preview: element.is_preview,
          });
        } else if (element.lesson) {
          temp.push({
            lesson: element.lesson._id,
            type: "lesson",
            is_preview: element.is_preview,
          });
        }
      });
      this.section.items = temp;
      sectionsApi.updatesection(this.section).then((response) => {
        if (response.data) {
          // this.$emit("updatesectionFromlist", this.section);
          console.log(response.data);
          this.section = response.data?.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "section updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>
