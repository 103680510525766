<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-prevent-sendmail
      variant="outline-success"
    >
      <feather-icon icon="SendIcon" />

      Send mail
    </b-button>

    <!-- modal data -->

    <!-- modal -->
    <b-modal
      id="modal-prevent-sendmail"
      ref="my-modal-sendmail"
      title="Send Message"
      ok-title="Create"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="messageInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">message Details</h5>
              <small class="text-muted"> Enter the message Details. </small>
            </b-col>
            <b-col md="12">
              <b-form-group label="subject" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="subject"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="subject"
                    v-model="message.subject"
                    :state="errors.length > 0 ? false : null"
                    placeholder="message subject"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="message" label-for="message">
                <validation-provider
                  #default="{ errors }"
                  name="message"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="message"
                    placeholder="message"
                    v-model="message.message"
                    :state="errors.length > 0 ? false : null"
                    rows="8"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import userApi from "@/apiServices/userApi";
import imagesApi from "@/apiServices/imagesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["selectedStudents"],
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
    message: {},
  }),
  created() {
    this.message.students = this.selectedStudents;
  },

  methods: {
    createmessages(messageFormData) {
      userApi.createmessage(messageFormData).then((response) => {
        if (response) {
          console.log(response);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Message Was sent",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    createmessageValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.messageInfoRules.validate().then((success) => {
          if (success) {
            this.message.students = this.selectedStudents;
            this.createmessages(this.message);
            this.$refs["my-modal-sendmail"].toggle("#toggle-btn");
            this.message = {};

            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.createmessageValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal-sendmail"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
