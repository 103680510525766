<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle="'sidebar-lesson-1' + id"
      variant="outline-primary mr-50"
    >
      choose lesson
    </b-button>

    <b-sidebar
      :id="'sidebar-lesson-1' + id"
      shadow
      bg-variant="white"
      backdrop
      right
    >
      <lessonsList :id="id" @addlessons="addlessons" />
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import lessonsList from "@/views/lessons pages/components/listCheckBox.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BImg,
    lessonsList,
  },
  props: ["id"],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    addlessons(selectedlessons) {
      console.log("this" + selectedlessons);
      this.$emit("addlessons", selectedlessons);
    },
  },
  data() {
    return {
      selectedlessons: [],
    };
  },
};
</script>
