<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-prevent-closing
      variant="outline-primary"
    >
      +
    </b-button>

    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-add"
      title="Add new section"
      ok-title="Create"
      cancel-variant="outline-secondary"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="sectionInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">section Details</h5>
              <small class="text-muted"> Enter the section Details. </small>
            </b-col>
            <b-col md="12">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="section.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="section title "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- 
            <b-col md="12">
              <b-form-group label="Content" label-for="Content">
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="Content"
                    placeholder="Content"
                    v-model="section.description"
                    :state="errors.length > 0 ? false : null"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-card
                v-for="(item, index) in section.items"
                :id="item.id"
                :key="item.id"
                class="m-1"
              >
                <b-row>
                  <b-col v-if="item.quiz" md="9">
                    <feather-icon v-if="item.quiz" icon="CheckSquareIcon" />
                    {{ item.quiz.title }}
                  </b-col>
                  <b-col v-else md="9">
                    <feather-icon icon="FileTextIcon" />
                    <b-avatar :src="item.lesson.imageCover" />
                    {{ item.lesson.title }}
                  </b-col>

                  <b-col md="3">
                    <b-row>
                      <b-col md="9">
                        <b-card-text class="mb-0"> preview </b-card-text>
                        <b-form-checkbox
                          class="custom-control-success"
                          name="check-button"
                          switch
                          v-model="item.is_preview"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="EyeIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="EyeOffIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="section.items.splice(index, 1)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
              <br />
              <b-row>
                <b-col>
                  <quizesSidebar @addquizes="addquizes" />
                </b-col>
                <b-col>
                  <lessonsSidebar @addlessons="addlessons" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import sectionsApi from "@/apiServices/sectionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import addQuestionsRepiter from "@/views/questions pages/components/repiter.vue";
import quizesSidebar from "@/views/quizes pages/components/quiziesSidebar.vue";
import lessonsSidebar from "@/views/lessons pages/components/lessonsSidebar.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // addQuestionsRepiter,
    quizesSidebar,
    lessonsSidebar,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
    section: {
      title: "",
      description: "",
      items: [],
    },
  }),
  methods: {
    createsections(sectionFormData) {
      sectionsApi.createsection(sectionFormData).then((response) => {
        if (response.data) {
          //   this.section = response.data;
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "section Created complite adding the Questions  and answers",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    addlessons(lessons) {
      lessons.forEach((element) => {
        let temp = {
          lesson: element,
          type: "lesson",
          is_preview: false,
        };
        if (
          this.section.items.findIndex(
            (element) => element.lesson?._id === temp.lesson._id
          ) === -1
        ) {
          this.section.items.push(temp);
        }
      });
    },

    addquizes(quizes) {
      quizes.forEach((element) => {
        let temp = {
          quiz: element,
          type: "quiz",
          is_preview: false,
        };
        if (
          this.section.items.findIndex(
            (element) => element.quiz?._id === temp.quiz?._id
          ) === -1
        ) {
          this.section.items.push(temp);
        }
      });
    },
    createsectionValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.sectionInfoRules.validate().then((success) => {
          if (success) {
            // const sectionFormData = new FormData();
            // Object.entries(this.section).forEach(([key, value]) => {
            //   sectionFormData.append(key, value);
            // });
            let temp = [];
            this.section.items.forEach((element) => {
              if (element.quiz) {
                temp.push({
                  quiz: element.quiz._id,
                  type: "quiz",
                  is_preview: element.is_preview,
                });
              } else if (element.lesson) {
                temp.push({
                  lesson: element.lesson._id,
                  type: "lesson",
                  is_preview: element.is_preview,
                });
              }
            });
            this.section.items = temp;
            this.createsections(this.section);
            this.$refs["my-modal-add"].toggle("#toggle-btn");
            this.section = {};
            resolve(true);
          } else {
            console.log("reasdsadasd");
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.createsectionValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal-add"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
