<template>
  <div>
    <b-overlay :show="show" rounded="sm">

      <form-wizard v-if="course" color="#00cfe8" :title="null" :subtitle="null" shape="circle"
        finish-button-text="update course" back-button-text="Previous" class="mb-3" @on-complete="infCourseValidation">
        <tab-content title="Course Details">
          <validation-observer ref="courseInfoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Course Details</h5>
                <small class="text-muted"> Enter the Course Details. </small>
              </b-col>
              <b-col md="6">
                <b-form-group label="title" label-for="name">
                  <validation-provider #default="{ errors }" name="title" rules="required|min:3">
                    <b-form-input id="title" v-model="course.title" :state="errors.length > 0 ? false : null"
                      placeholder="couse name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="course duration in monthes  " label-for="duration">
                  <validation-provider #default="{ errors }" name="duration" rules="required|numeric">
                    <b-form-input id="duration" v-model="course.duration" :state="errors.length > 0 ? false : null"
                      placeholder="course duration in monthes" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="price " label-for="price">
                  <validation-provider #default="{ errors }" name="price" rules="required|numeric">
                    <b-form-input id="price" v-model="course.price" :state="errors.length > 0 ? false : null"
                      placeholder="price" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="priceAfterDiscount" label-for="priceAfterDiscount">
                  <validation-provider #default="{ errors }" name="priceAfterDiscount"
                    :rules="`numeric|max_value:${course.price}`">
                    <b-form-input id="priceAfterDiscount" v-model="course.priceAfterDiscount"
                      :state="errors.length > 0 ? false : null" placeholder="price After Discount" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="description" label-for="description">
                  <validation-provider #default="{ errors }" name="description" rules="required|min:20">
                    <b-form-textarea id="description" placeholder="description" v-model="course.description"
                      :state="errors.length > 0 ? false : null" rows="8" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Course Cover Image" label-for="CorseCoverImage">
                  <validation-provider #default="{ errors }" name="CorseCoverImage" rules="required">
                    <b-form-file :state="Boolean(course.imageCover)" id="CorseCoverImage" accept="image/*"
                      v-model="course.imageCover" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row>
                  <b-col md="6">
                    <validation-provider #default="{ errors }" name="category" rules="required">
                      <b-form-group label="select Course Category" label-for="category">
                        <v-select v-model="course.category" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name" id="category" :options="categories" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content title="Course Content">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Course Content</h5>
              <small class="text-muted">Enter Course Sections .</small>
            </b-col>
          </b-row>

          <b-row v-if="course._id">
            <b-card class="w-100">
              <sectionSidebar @addsections="addsections" />
            </b-card>
          </b-row>
          <draggable v-model="course.sections" handle=".draggable-task-handle" tag="ul" class="p-0">
            <b-row v-if="course._id" v-for="(section, index) in course.sections" :id="section.id" :key="section.id">
              <b-card class="w-100">
                <feather-icon icon="MoreVerticalIcon" class="draggable-task-handle d-inline" />
                <b-button v-b-toggle="`collapse-1${section._id}`" v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="outline-dark" class="w-100">See {{ section.title }} Content
                  <feather-icon icon="ChevronsDownIcon" />
                </b-button>
                <b-collapse :id="`collapse-1${section._id}`" class="mt-2">
                  <sectionCard :section="section"> </sectionCard>
                </b-collapse>

                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" class="btn-icon mt-50 w-100"
                  @click="course.sections.splice(index, 1)">
                  <feather-icon icon="Trash2Icon" />
                  <span class="mr-50">delete ====> {{ section.title }}</span>
                </b-button>
              </b-card>
            </b-row>
          </draggable>
        </tab-content>
        <tab-content title="Students">
          <validation-observer ref="StudentsRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Course Content</h5>
                <small class="text-muted">Enter Course Sections .</small>
              </b-col>
            </b-row>

            <b-row>
              <b-card v-if="course._id" class="w-100">
                <b-row>
                  <userSidebar @addusers="addusers" />
                  <b-col md="6"></b-col>

                  <sendMail :selectedStudents="selectedStudents" v-if="selectedStudents.length > 0" />
                </b-row>
              </b-card>
            </b-row>
            <b-form-checkbox-group id="checkbox-group-2" v-model="selectedStudents" class="w-100">
              <b-row v-if="course._id" v-for="(user, index) in course.students" :id="user.id" :key="user.id"
                class="w-100">
                <b-card class="w-100" v-if="user.student">
                  <b-row>
                    <b-col md="3">
                      <b-form-checkbox :value="user.student">
                        <b-avatar :src="user.student.profileImg" />
                        {{ user.student.name }}
                        <br>
                        {{ user.student.email }}
                      </b-form-checkbox>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="start Date" label-for="start Date">
                        <validation-provider #default="{ errors }" name="start Date"
                          :vid="'register-startDate' + user.student._id" rules="required">
                          <b-form-datepicker :id="'register-startDate' + user.student._id" v-model="user.startDate"
                            name="register-expairation Date"
                            :state="errors.length > 0 ? false : null"></b-form-datepicker>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="expairation Date" label-for="expairation Date">
                        <validation-provider #default="{ errors }" name="expairation Date"
                          :vid="'register-expairationDate' + user.student._id" rules="required">
                          <b-form-datepicker :id="'register-expairationDate' + user.student._id"
                            v-model="user.expairationDate" name="register-expairation Date"
                            :state="errors.length > 0 ? false : null"></b-form-datepicker>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" class="btn-icon"
                        @click="deleteStudentFromCourse(user, index)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="mr-50">remove </span>
                      </b-button>
                    </b-col>
                  </b-row>

                  <!-- <userCard :user="user"> </userCard> -->
                </b-card>
              </b-row>
            </b-form-checkbox-group>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, email, max_value, numeric } from "@validations";
// import { codeIcon } from "./code";
import categoriesApi from "@/apiServices/categoriesApi";
import coursesApi from "@/apiServices/coursesApi";
import imagesApi from "@/apiServices/imagesApi";
import sectionSidebar from "@/views/sections pages/components/sectionsSidebar.vue";
import userSidebar from "@/views/users pages/components/usersSidebar.vue";
import sectionCard from "@/views/courses pages/components/sectionCard.vue";
import sendMail from "@/views/courses pages/components/sendmail.vue";
import useriesApi from "@/apiServices/userApi";
import draggable from "vuedraggable";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    sectionSidebar,
    sectionCard,
    userSidebar,
    sendMail,
    draggable,
  },
  created() {
    this.getCourse();
    this.getCategories();
  },
  data() {
    return {
      show: false,
      selectedStudents: [],
      required: required,
      email: email,
      numeric: numeric,
      max_value: max_value,
      course: {
        title: "",
        description: "",
        price: null,
        duration: null,
        priceAfterDiscount: null,
        imageCover: null,
        category: "",
        sections: [],
        students: [],
        // subcategories: [],
        // sections: [],
      },
      categories: [],
      previous: {},
    };
  },
  watch: {
    course() {
      console.log(
        JSON.stringify(this.previous) === JSON.stringify(this.course)
      );
    },
  },
  methods: {
    getCourse() {
      coursesApi.getCourse(this.$route.params.id).then((response) => {
        this.courses = [];
        if (response.data) {
          console.log(response.data);
          this.course = response.data?.data;
          this.previous = response.data?.data;
          console.log(
            JSON.stringify(this.previous) === JSON.stringify(this.course)
          );
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.course.title,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    getCategories() {
      categoriesApi.getCategories().then((response) => {
        if (response.data) {
          this.categories = response.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get categories",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    addusers(students) {
      console.log("this is fron edit" + students);
      students.forEach((element1) => {
        if (
          this.course.students.findIndex(
            (element) => element?.student?._id === element1._id
          ) === -1
        ) {
          let start = new Date();
          let end = new Date(start);
          end.setMonth(end.getMonth() + this.course.duration);
          this.course.students.push({
            student: element1,
            expairationDate: end,
            startDate: start
          });
        }
      });
    },
    addsections(sections) {
      sections.forEach((element1) => {
        if (
          this.course.sections.findIndex(
            (element) => element._id === element1._id
          ) === -1
        ) {
          this.course.sections.push(element1);
        }
      });
    },

    deleteStudentFromCourse(user, index) {

      this.course.students.splice(index, 1);
      user.student.courses = user.student.courses.filter(
        (item) => item.course !== this.course._id
      );
      this.studentUpdate(user.student);
    },
    studentUpdate(user) {
      useriesApi.updateuser(user).then((response) => {
        if (response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Students list Updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    async addCouseToStudent(user) {
      if (
        user.courses.findIndex(
          (element) => element.course === this.course._id
        ) === -1
      ) {
        user.courses.push({ course: this.course._id, sectionOrder: 0 });
      }

      await this.studentUpdate(user);
    },
    myLoop() {
      //  create a loop function
    },
    async updateCoures(courseFormData) {
      this.show = true;
      coursesApi.updateCourse(courseFormData).then((response) => {
        if (response.data) {
          this.course = response.data?.data;
          const students = this.course.students;
          const timer = ms => new Promise(res => setTimeout(res, ms))

          for (const [index, element] of students.entries()) {
            this.addCouseToStudent(element.student);

            timer(60000)

            if (index === students.length - 1) {
              this.show = false;
            }
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "course updated complited",
              icon: "EditIcon",
              variant: "success",
            },
          });

        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });

        }
      });
    },
    infCourseValidation() {
      this.show = true;
      if (this.show) {
        return new Promise((resolve, reject) => {
          this.$refs.StudentsRules.validate().then((success) => {
            if (success) {
              this.course.category = this.course?.category?._id;
              this.course.sections = this.course.sections.map((a) => a._id);

              this.course.students?.forEach((element) => {
                if (element && element.student) {
                  element.student = element?.student?._id;
                }
              });

              if (this.course.imageCover instanceof File) {
                const imageFormData = new FormData();
                imageFormData.append("image", this.course.imageCover);

                imagesApi.uploadImage(imageFormData).then((response) => {
                  console.log(response);
                  this.course.imageCover = response;
                  this.updateCoures(this.course);
                  resolve(true);
                });
              } else {
                delete this.course.imageCover;
                this.updateCoures(this.course);
                resolve(true);
              }



            } else {
              reject();
            }
          });
        });
      } else {

      }

    },
  },
};
</script>
<style lang="scss" scoped>
.draggable-task-handle {
  left: 8px;
  cursor: move;
}
</style>
