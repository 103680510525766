import axiosApi from "../axios";
export default {
  async getsections(pramas) {
    return await axiosApi
      .get(`/sections`, { params: pramas })
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },

  async updatesection(section) {
    return await axiosApi
      .put(`/sections/${section._id}`, section)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async createsection(section) {
    return await axiosApi
      .post(`/sections`, section)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async deletesection(pramas) {
    return await axiosApi
      .delete(`/sections/${pramas.id}`)
      .then((res) => {
        return res.status;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response;
      });
  },
};
