<template>
  <div>
    <!-- button -->
    <b-button
      class="btn-icon"
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.addStudent
      variant="success"
    >
      <feather-icon icon="UserPlusIcon" />
    </b-button>

    <!-- modal data -->

    <!-- modal -->
    <b-modal
      id="addStudent"
      ref="addStudent"
      title="Add new user"
      ok-title="Create"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="userInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">user Details</h5>
              <small class="text-muted"> Enter the user Details. </small>
            </b-col>
            <b-col md="12">
              <b-form-group
                class="auth-register-form mt-2"
                label="user profile image"
                label-for="CorseCoverImage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CorseCoverImage"
                  rules="required"
                >
                  <b-form-file
                    :state="Boolean(user.imageCover)"
                    id="CorseCoverImage"
                    accept="image/*"
                    v-model="user.profileImg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="auth-register-form w-100"
                label="name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="user name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="phone" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="register-phone"
                    v-model="user.phone"
                    name="register-phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="01000000000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="birthDate" label-for="birthDate">
                <validation-provider
                  #default="{ errors }"
                  name="birthDate"
                  vid="birthDate"
                  rules="required"
                >
                  <b-form-datepicker
                    id="register-birthDate"
                    v-model="user.birthDate"
                    name="register-birthDate"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group label="Country" label-for="country">
                  <v-select
                    v-model="user.nationality"
                    :options="countries"
                    :clearable="false"
                    input-id="country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                  <!-- <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback> -->
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- password -->
            <b-col md="6">
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:9"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-for="register-confirm-password"
                label="confirm password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password Confirmation"
                  vid="passwordConfirm"
                  rules="required|confirmed:password"
                  data-vv-as="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password-confirm"
                      v-model="user.passwordConfirm"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import userApi from "@/apiServices/userApi";
import imagesApi from "@/apiServices/imagesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import countries from "@/auth/countries";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    required,
    email,
    max_value,
    numeric,
    countries,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
    user: {},
  }),
  methods: {
    createusers(userFormData) {
      userApi.createuser(userFormData).then((response) => {
        if (response.data) {
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "user Created",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },

    createuserValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.userInfoRules.validate().then((success) => {
          if (success) {
            const imageFormData = new FormData();
            imageFormData.append("image", this.user.profileImg);
            imagesApi.uploadImage(imageFormData).then((response) => {
              console.log(response);
              this.user.profileImg = response;
              this.createusers(this.user);
              this.$refs["addStudent"].toggle("#toggle-btn");
              this.user = {};
            });

            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.createuserValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["addStudent"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
