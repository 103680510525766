<template>
  <b-card-code title="All users " no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->

        <!-- filter -->
        <b-row class="w-100">
          <b-col md="9">
            <b-form-group label-size="sm" label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search by name or email" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <addNewuser />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <b-form-checkbox v-model="allSelected" :indeterminate="indeterminate" aria-describedby="checkbox-group-2"
            aria-controls="checkbox-group-2" @change="toggleAll">
            {{ allSelected ? 'Un-select All' : 'Select All' }}
          </b-form-checkbox>
          <b-form-checkbox-group id="checkbox-group-2" v-model="selectedusers" class="demo-inline-spacing">
            <b-row v-for="(user, index) in users" :id="user.id" :key="user.id">
              <b-col md="12">
                <b-form-checkbox :value="user">
                  <b-avatar :src="user.profileImg" />
                  {{ user.name }}
                  <br>
                  {{ user.email }}

                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="prams.limit" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-col md="12">
      <b-button variant="success" class="mt-0 mt-md-2 w-100" @click="addusers()">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add The users</span>
      </b-button>
    </b-col>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import usersApi from "@/apiServices/userApi";
import deleteuser from "@/views/users pages/delete.vue";
import edituser from "@/views/users pages/edit.vue";
import addNewuser from "@/views/users pages/components/add.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    deleteuser,
    addNewuser,
    edituser,
  },
  data() {
    return {
      selectedusers: [],
      allSelected: false,
      indeterminate: false,
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 100,
      },
      options: ["single_choice", "multi_choice", "true_false"],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      users: [],
    };
  },
  created() {
    // this.getusers();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;
      this.getusers();
    },
    filter() {
      this.prams.page = this.currentPage;
      if (this.containsEmail(this.filter)) {
        this.prams.email = this.filter;
        delete this.prams.name

        this.getusers();
      }
      else {
        this.prams.name = this.filter;
        delete this.prams.email

        this.getusers();

      }

    },
  },
  methods: {
    toggleAll(checked) {
      this.selectedusers = checked ? this.users : []
    },
    addusers() {
      console.log("this" + this.selectedusers);
      try {
        this.$emit("addusers", this.selectedusers);
      } catch (e) {
        console.log(e);
      }
    },
    containsEmail(inputString) {
      var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

      return emailPattern.test(inputString);
    },
    getusers() {

      usersApi.getusers(this.prams).then((response) => {
        this.users = [];
        if (response.data) {
          this.users = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All users",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
