<template>
  <div>
    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.sidebar-section-1 variant="outline-primary mr-50">
      choose Students
    </b-button>

    <b-sidebar id="sidebar-section-1" shadow bg-variant="white" backdrop width="450px">
      <quiziesList @addusers="addusers" />
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import quiziesList from "@/views/users pages/components/listCheckBox.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BImg,
    quiziesList,
  },
  //   props: ["selectedusers"],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    addusers(selectedusers) {
      console.log("this" + selectedusers);
      this.$emit("addusers", selectedusers);
    },
  },
  data() {
    return {
      selectedusers: [],
    };
  },
};
</script>
