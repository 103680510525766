import axiosApi from "../axios";
export default {
  async getLessons(pramas) {
    return await axiosApi
      .get(`/lessons`, { params: pramas })
      .then((res) => {
        console.log(res.data + "this is data ");
        return res.data;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },

  async updateLesson(Lesson) {
    return await axiosApi
      .put(`/lessons/${Lesson._id}`, Lesson)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async createLesson(Lesson) {
    return await axiosApi
      .post(`/lessons`, Lesson)
      .then((res) => {
        console.log(res.data + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
  async deleteLesson(pramas) {
    return await axiosApi
      .delete(`/lessons/${pramas.id}`)
      .then((res) => {
        return res.status;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response;
      });
  },
};
