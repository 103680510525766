<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <!-- modal -->
    <b-modal title="Update " ref="my-modal" modal-class="modal-info" hide-footer size="lg">
      <b-overlay :show="show1" rounded="sm">

        <form ref="form" @submit.stop.prevent="handleSubmit">
          <validation-observer ref="sectionInfoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">section Details</h5>
                <small class="text-muted"> Enter the section Details. </small>
              </b-col>
              <b-col md="12">
                <b-form-group label="title" label-for="name">
                  <validation-provider #default="{ errors }" name="title" rules="required|min:3">
                    <b-form-input id="title" v-model="section.title" :state="errors.length > 0 ? false : null"
                      placeholder="section title " />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- 
            <b-col md="12">
              <b-form-group label="Content" label-for="Content">
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="Content"
                    placeholder="Content"
                    v-model="section.description"
                    :state="errors.length > 0 ? false : null"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
              <b-col>
                <draggable v-model="section.items" handle=".drag-sections" tag="ul" class="p-0">
                  <b-card v-for="(item, index) in section.items" :id="item.id" :key="item.id" class="m-1">
                    <b-row>
                      <feather-icon icon="MoreVerticalIcon" class="drag-sections" />
                      <b-col v-if="item.quiz" md="8">
                        <feather-icon icon="CheckSquareIcon" />
                        {{ item.quiz.title }}
                      </b-col>
                      <b-col v-else-if="item.lesson" md="8">
                        <feather-icon icon="FileTextIcon" />
                        <b-avatar :src="item.lesson.imageCover" />
                        {{ item.lesson.title }}
                      </b-col>

                      <b-col md="2">
                        <b-row>
                          <b-col md="9">
                            <b-card-text class="mb-0"> preview </b-card-text>
                            <b-form-checkbox class="custom-control-success" name="check-button" switch
                              v-model="item.is_preview">
                              <span class="switch-icon-left">
                                <feather-icon icon="EyeIcon" />
                              </span>
                              <span class="switch-icon-right">
                                <feather-icon icon="EyeOffIcon" />
                              </span>
                            </b-form-checkbox>
                          </b-col>
                          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" class="btn-icon"
                            @click="section.items.splice(index, 1)">
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </draggable>

                <br />
                <b-row>
                  <b-col>
                    <quizesSidebar @addquizes="addquizes" />
                  </b-col>
                  <b-col>
                    <lessonsSidebar @addlessons="addlessons" />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </form>
        <b-row variant="info">
          <b-col md="4"> </b-col>
          <b-col md="3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-secondary" block
              @click="hideModal">
              No
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-info" block
              @click="toggleModal">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span class="mr-50 text-info">update</span>
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import sectionsApi from "@/apiServices/sectionsApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addQuestionsRepiter from "@/views/questions pages/components/repiter.vue";
import quizesSidebar from "@/views/quizes pages/components/quiziesSidebar.vue";
import lessonsSidebar from "@/views/lessons pages/components/lessonsSidebar.vue";
import draggable from "vuedraggable";

export default {
  props: ["section"],
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    quizesSidebar,
    lessonsSidebar,
    draggable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      show1: false,

      prams: {
        id: "",
      },
    };
  },
  created() {
    // this.section.questions = this.section.questions.map((a) => a.questionId);
  },
  methods: {
    addlessons(lessons) {
      lessons.forEach((element) => {
        let temp = {
          lesson: element,
          type: "lesson",
          is_preview: false,
        };
        if (
          this.section.items.findIndex(
            (element) => element.lesson?._id === temp.lesson._id
          ) === -1
        ) {
          this.section.items.push(temp);
        }
      });
    },

    addquizes(quizes) {
      quizes.forEach((element) => {
        let temp = {
          quiz: element,
          type: "quiz",
          is_preview: false,
        };
        if (
          this.section.items.findIndex(
            (element) => element.quiz?._id === temp.quiz?._id
          ) === -1
        ) {
          this.section.items.push(temp);
        }
      });
    },
    updatesection() {
      let temp = [];
      this.show1 = true;

      this.section.items.forEach((element) => {
        if (element.quiz) {
          temp.push({
            quiz: element.quiz._id,
            type: "quiz",
            is_preview: element.is_preview,
          });
        } else if (element.lesson) {
          temp.push({
            lesson: element.lesson._id,
            type: "lesson",
            is_preview: element.is_preview,
          });
        }
      });
      this.section.items = temp;

      sectionsApi.updatesection(this.section).then((response) => {
        if (response.data) {
          this.$emit("updatesectionFromlist", this.sectio);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "section updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show1 = false;
          this.$refs["my-modal"].toggle("#toggle-btn");

        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.show1 = false;
          this.$refs["my-modal"].toggle("#toggle-btn");

        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.updatesection();
    },
  },
};
</script>
