<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle="'sidebar-Quize-1'+ id""
      variant="outline-primary mr-50"
    >
      choose quizies
    </b-button>

    <b-sidebar :id="'sidebar-Quize-1'+ id" shadow bg-variant="white" backdrop>
      <quiziesList :id="id" @addquizes="addquizes" />
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import quiziesList from "@/views/quizes pages/components/listCheckBox.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BImg,
    quiziesList,
  },
  //   props: ["selectedquizies"],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: ["id"],

  methods: {
    addquizes(selectedquizies) {
      console.log("this" + selectedquizies);
      this.$emit("addquizes", selectedquizies);
    },
  },
  data() {
    return {
      selectedquizies: [],
    };
  },
};
</script>
