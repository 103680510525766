<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <!-- <b-button
        id="show-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        delete
      </b-button> -->

    <!-- modal -->
    <b-modal title="Update quize" ref="my-modal" modal-class="modal-info" hide-footer size="xl">
      <!-- @show="resetModal"
      @hidden="resetModal"
      @ok="toggleModal" -->
      <b-overlay :show="show1" rounded="sm">

        <form ref="form" @submit.stop.prevent="handleSubmit">
          <validation-observer ref="quizeInfoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">quize Details</h5>
                <small class="text-muted"> Enter the quize Details. </small>
              </b-col>
              <b-col md="12">
                <b-form-group label="title" label-for="name">
                  <validation-provider #default="{ errors }" name="title" rules="required|min:3">
                    <b-form-input id="title" v-model="quize.title" :state="errors.length > 0 ? false : null"
                      placeholder="Quize title " />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="grad" label-for="name">
                  <validation-provider #default="{ errors }" name="grad" rules="required|numeric">
                    <b-form-input id="grad" v-model="quize.grad" :state="errors.length > 0 ? false : null"
                      placeholder="grade" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="timer" label-for="name">
                  <validation-provider #default="{ errors }" name="timer" rules="required|numeric">
                    <b-form-input id="timer" v-model="quize.timer" :state="errors.length > 0 ? false : null"
                      placeholder="timer" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="12">
              <b-form-group label="Content" label-for="Content">
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="Content"
                    placeholder="Content"
                    v-model="quize.description"
                    :state="errors.length > 0 ? false : null"
                    rows="8"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
              <b-row>
                <b-col v-if="quize.questions[0]._id != null">
                  <addQuestionsRepiter :questions="quize.questions" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <questionsSidebar @addQuestions="addQuestions" />
                </b-col>
              </b-row>
            </b-row>
          </validation-observer>
        </form>
        <b-row variant="info">
          <b-col md="4"> </b-col>
          <b-col md="3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-secondary" block
              @click="hideModal">
              No
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-info" block
              @click="toggleModal">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span class="mr-50 text-info">update</span>
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import quizesApi from "@/apiServices/quizesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addQuestionsRepiter from "@/views/questions pages/components/repiter.vue";
import questionsSidebar from "@/views/questions pages/components/questionsSidebar.vue";

export default {
  props: ["quize"],
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addQuestionsRepiter,
    questionsSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      show1: false,
      prams: {
        id: "",
      },
    };
  },
  created() {
    // this.quize.questions = this.quize.questions.map((a) => a.questionId);
  },
  mounted() {
    // this.quize.questions = this.quize.questions.map((a) => a.questionId);
  },
  methods: {
    addQuestions(questions) {
      console.log("sadasdasdad");
      questions.forEach((element) => {
        this.quize.questions.push(element);
      });
    },
    async getQuiz() {
      this.show1 = true;
      await quizesApi.getQuize(this.quize._id).then((response) => {
        if (response.data) {
          this.quize = response.data;
          console.log(response.data);
          this.show1 = false;

        } else {

        }
      });

    },
    updatequize() {
      let temp = [];
      this.show1 = true;

      this.quize.questions.forEach((element) => {
        temp.push({
          questionId: element._id,
          marks: element.grad,
        });
      });
      this.quize.questions = temp;

      quizesApi.updatequize(this.quize).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$emit("updatequizeFromlist", this.id);
          this.show1 = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "quize updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$refs["my-modal"].toggle("#toggle-btn");

        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.show1 = false;
          this.$refs["my-modal"].toggle("#toggle-btn");

        }
      });
    },
    showModal() {

      try {
        this.getQuiz().then(e => {
          this.quize.questions = this.quize.questions.map((a) => a.questionId);
        }

        )

      } catch (error) {
      } finally {
        this.$refs["my-modal"].show();
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.updatequize();
      // this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>
