<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="TrashIcon" class="mr-50 text-danger" />
          <span class="mr-50 text-danger">Delete</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <!-- <b-button
        id="show-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        delete
      </b-button> -->

    <!-- modal -->
    <b-modal
      ref="my-modal"
      modal-class="modal-danger"
      hide-footer
      title="Delete section"
    >
      <div class="d-block text-center">
        <h3>Are you Sure</h3>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="outline-secondary"
        block
        @click="hideModal"
      >
        No
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="outline-danger"
        block
        @click="toggleModal"
      >
        <feather-icon icon="TrashIcon" class="mr-50 text-danger" />
        <span class="mr-50 text-danger">Delete</span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import sectionsApi from "@/apiServices/sectionsApi";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["id"],

  components: {
    BButton,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      prams: {
        id: "",
      },
    };
  },
  created() {
    // console.log(this.id);
  },
  methods: {
    deletesection() {
      this.prams.id = this.id;
      sectionsApi.deletesection(this.prams).then((response) => {
        if (response.status != "fail") {
          this.course = response.data;
          // console.log(response.data);
          this.$emit("deletesectionFromlist", this.id);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "section deleted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.deletesection();
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>
