<template>
  <b-card-code title="All quizes " no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->

        <!-- filter -->
        <b-row>
          <b-col md="9">
            <b-form-group label-size="sm" label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <addNewQuize :id="id" />
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <b-form-checkbox-group id="checkbox-group-2" v-model="selectedquizes" class="demo-inline-spacing">
            <b-row v-for="(quize, index) in quizes" :id="quize.id" :key="quize.id">
              <b-col md="12">
                <b-form-checkbox :value="quize">
                  <!-- <b-avatar :src="quize.imageCover" /> -->
                  {{ quize.title }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="prams.limit" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-col md="12">
      <b-button variant="success" class="mt-0 mt-md-2 w-100" @click="addquizes()">
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add The quizes</span>
      </b-button>
    </b-col>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import quizesApi from "@/apiServices/quizesApi";
import deletequize from "@/views/quizes pages/delete.vue";
import editquize from "@/views/quizes pages/edit.vue";
import addNewQuize from "@/views/quizes pages/components/add.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    deletequize,
    addNewQuize,
    editquize,
  },
  props: ["id"],
  data() {
    return {
      selectedquizes: [],
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 100,
        title: "",
      },
      options: ["single_choice", "multi_choice", "true_false"],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      quizes: [],
    };
  },
  created() {
    // this.getquizes();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;

      this.getquizes();
    },
    filter() {
      this.prams.page = this.currentPage;
      this.prams.title = this.filter;

      this.getquizes();
    },
  },
  methods: {
    addquizes() {
      console.log("this" + this.selectedquizes);
      try {
        this.$emit("addquizes", this.selectedquizes);
      } catch (e) {
        console.log(e);
      }
    },
    getquizes() {
      if (this.filter == "") {
        delete this.prams.title;
      }
      quizesApi.getquizies(this.prams).then((response) => {
        this.quizes = [];
        if (response.data) {
          this.quizes = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All quizes",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
