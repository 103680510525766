<template>
  <div>
    <!-- button -->
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal="'my-modal-add-quize' + id"
      variant="outline-primary"
    >
      +
    </b-button>

    <!-- modal data -->

    <!-- modal -->
    <b-modal
      :id="'my-modal-add-quize' + id"
      :ref="'my-modal-add-quize' + id"
      title="Add new quize"
      ok-title="Create"
      cancel-variant="outline-secondary"
      size="xl"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="quizeInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">quize Details</h5>
              <small class="text-muted"> Enter the quize Details. </small>
            </b-col>
            <b-col md="12">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="quize.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Quize title "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="grad" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="grad"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="grad"
                    v-model="quize.grad"
                    :state="errors.length > 0 ? false : null"
                    placeholder="grade"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="timer" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="timer"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="timer"
                    v-model="quize.timer"
                    :state="errors.length > 0 ? false : null"
                    placeholder="timer"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col md="12">
              <b-form-group label="Content" label-for="Content">
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="Content"
                    placeholder="Content"
                    v-model="quize.description"
                    :state="errors.length > 0 ? false : null"
                    rows="8"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-row>
              <b-col md="12">
                <addQuestionsRepiter :questions="quize.questions" />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <questionsSidebar @addQuestions="addQuestions" />
              </b-col>
            </b-row>
          </b-row>
        </validation-observer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import quizesApi from "@/apiServices/quizesApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import addQuestionsRepiter from "@/views/questions pages/components/repiter.vue";
import questionsSidebar from "@/views/questions pages/components/questionsSidebar.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    addQuestionsRepiter,
    questionsSidebar,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["id"],

  data: () => ({
    required,
    email,
    max_value,
    numeric,
    name: "",
    nameState: null,
    options: ["video", "pdf", "powerpoint"],
    submittedNames: [],
    quize: {
      title: "",
      description: "",
      grad: 0,
      questions: [],
    },
  }),
  methods: {
    createquizes(quizeFormData) {
      quizesApi.createquize(quizeFormData).then((response) => {
        if (response.data) {
          //   this.quize = response.data;
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "quize Created complite adding the Questions  and answers",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    addQuestions(questions) {
      console.log("sadasdasdad");
      questions.forEach((element) => {
        this.quize.questions.push(element);
      });
    },
    createquizeValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.quizeInfoRules.validate().then((success) => {
          if (success) {
            // const quizeFormData = new FormData();
            // Object.entries(this.quize).forEach(([key, value]) => {
            //   quizeFormData.append(key, value);
            // });
            let temp = [];
            this.quize.questions.forEach((element) => {
              temp.push({
                questionId: element._id,
                marks: element.grad,
              });
            });
            this.quize.questions = temp;
            this.createquizes(this.quize);
            this.quize = {};
            resolve(true);
          } else {
            console.log("reasdsadasd");
            reject();
          }
        });
      });
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.createquizeValidation();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal-add-quize"].toggle("#toggle-btn");
      });
    },
  },
};
</script>
